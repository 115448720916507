<template>
  <MainComponent/>
</template>

<script>
import MainComponent from './components/MainComponent.vue'


export default {
  name: 'App',
  components: {
    MainComponent
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#app {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

.dark {
  background-color: #1d1b1b;
  color: #dddddd;
}

</style>
