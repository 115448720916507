<template>
    <div class="main-container">
        <div class="container-one">
            <div class="dark-toggle">
                <img src="../assets/dayandnight.png" alt="dark-light-toggle" @click="toggleDark()">
            </div>
            <div class="profile">
                <h1>Ahmed Gheriani</h1>
                <h3>Web Developer</h3>
            </div>
            <div class="social-links">
                <div class="link">
                    <a href="mailto:ahmed@gheriani.dev" target="_blank">
                        <img src="../assets/email.png" alt="email">
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.github.com" target="_blank">
                        <img src="../assets/github.png" alt="github">
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.linkedin.com" target="_blank">
                        <img src="../assets/linkedin.png" alt="linkedin">
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.instagram.com" target="_blank">
                        <img src="../assets/instagram.png" alt="instagram">
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.twitter.com" target="_blank">
                        <img src="../assets/twitter.png" alt="twitter">
                    </a>
                </div>
            </div>
        </div>
        <div class="container-two">
            <div class="about-me">
                <h1>About Me</h1>
                <p>Hi, my name is Ahmed and I'm a web developer based in Ontario, Canada.</p>
                <p>I'm passionate about making things with code. Like this website. We're still under construction - the plan is to showcase some of my projects and work experience here.</p>
                <p>For now, here's some information about me:</p>
                <ul>
                    <li>Computer Science graduate from the University of Ottawa (2022).</li>
                    <li>Experience with Web APIs, front end development, relational databases.</li>
                    <li>Currently a web developer at <span>[&#9608;&#9608;&#9608;&#9608;&#9608;]</span> in Ottawa.</li>
                </ul>
            </div>
            <div class="nav-buttons">
                <div class="nav-button">
                    <div class="button-img">
                        <img src="../assets/mac.png" alt="projects">
                    </div>
                    <div class="button-text">
                        <span>My Projects</span>
                    </div>
                </div>
                <div class="nav-button">
                    <div class="button-img">
                        <img src="../assets/resume.png" alt="resume">
                    </div>
                    <div class="button-text">
                        <span>My Resume</span>
                    </div>
                </div>
            </div>
            <div class="tech-stack">
                <h1>Technologies</h1>
                <div class="row">
                    
                    <div class="language__list">
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/dot-net-core.svg" alt="dot-net" class="language__img">
                            </figure>
                            <span class="language__name">.NET</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/c.svg" alt="c#" class="language__img">
                            </figure>
                            <span class="language__name">C#</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/python.svg" alt="python" class="language__img">
                            </figure>
                            <span class="language__name">Python</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/javascript.svg" alt="javascript" class="language__img">
                            </figure>
                            <span class="language__name">JavaScript</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/vue.svg" alt="vue" class="language__img">
                            </figure>
                            <span class="language__name">Vue.js</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/react.svg" alt="react" class="language__img">
                            </figure>
                            <span class="language__name">React</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/postgresql.svg" alt="postgresql" class="language__img">
                            </figure>
                            <span class="language__name">Postgresql</span>
                        </div>
                        <div class="language">
                            <figure class="language__img--wrapper">
                                <img src="../assets/java.svg" alt="java" class="language__img">
                            </figure>
                            <span class="language__name">Java</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>


import { useDark, useToggle } from '@vueuse/core'
const isDark = useDark();
isDark.value = false;
const toggleDark = useToggle(isDark);

</script>

<style scoped>

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.main-container {
    display: flex;
    flex-direction: row;
}

.container-one {
    width: 25%;
    padding: 0.5%;
    animation: fadeInAnimation ease 1.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.container-one img {
    height: 35px;
    margin-top: 15%;
}

.container-two {
    width: 75%;
    padding: 2.5%;
    border-left: solid;
    border-top: solid;
    animation: fadeInAnimation ease 1.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

/* ABOUT ME  */

.about-me {
    text-align: left;
    margin: 2% 5%;
}

.dark .social-links img {
    background: white;
    border: solid;
    border-color: black;
    padding: 1px;
}

/* NAV BUTTONS */

.nav-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: center; */
    margin: 2% 5%;
}

.nav-button {
    margin: 0 1em;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
}


.nav-button img {
    height: 35px;
}

.nav-button:disabled {
    
}

.nav-button:hover {
    color: #fff;
    background-color: #555555;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.nav-button:active {
    box-shadow: none;
    transform: translateY(0);
}

.dark .nav-button {
    background-color: white;
}

.dark .nav-button:hover {
    background-color: #555555;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}


/* TECH STACK */

.tech-stack {
    margin: 2% 5%;
    text-align: left;
    animation: fadeInAnimation ease 1.5s;
    animation-delay: 2.4s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.row {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 12px;
}

.language__img {
    width: 100%;
    max-width: 100px;
    transition: all 300ms;
}

.language:hover .language__img {
    filter: brightness(80%);
    opacity: 0.86;
    transform: scale(0.9);
}

.language {
    width: 25%;
    display: flex;
    justify-content: center;
    position: relative;
}

.language__img--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
}

.language__list {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.language__name {
    position: absolute;
    bottom: 0;
    transform: scale(0);
    transition: all 300ms;
    font-size: 20px;
    opacity: 0;
}
.language:hover .language__name {
    transform: scale(1);
    opacity: 1;
}


/* MEDIA */

@media (max-width: 900px) {
    .language {
        width: calc(100%/3)
    }
}

@media (max-width: 768px) {
    .language {
        width: calc(100% / 2);
    }
}

@media (max-width: 520px) {

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    p, li {
        font-size: 13px;
    }
}

@media (max-width: 425px) {
    .language {
        width: calc(100% / 2);
    }

    .language__img--wrapper {
        padding: 3px 1px;
    }

    .language__name {
        font-size: 14px;
    }

    .language:focus .language__name, .language:active .language__name {
        transform: scale(1);
        opacity: 1;
    }

}

</style>